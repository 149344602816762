<template>
  <v-row>
    <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
      <v-card class="mt-4">
        <v-card-text>
          <v-row>
            <v-col :cols="12" md="6">
              <h2 style="margin-bottom:10px">Performer: {{ studentName}}</h2>
              <v-list elevation="1">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Jury Date/Time</v-list-item-subtitle>
                    <v-list-item-title>{{ juryDate }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar>{{ juryTerm }}</v-list-item-avatar>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Instructor</v-list-item-subtitle>
                    <v-list-item-title>{{ instructor }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Instrument</v-list-item-subtitle>
                    <v-list-item-title>{{ instrument }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="accompanist">
                  <v-list-item-content>
                    <v-list-item-subtitle>Accompanist</v-list-item-subtitle>
                    <v-list-item-title>{{ accompanist }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col :cols="12" md="6">
              <h2 style="margin-bottom:10px">Repertoire</h2>
              <v-list elevation="1">
                <v-list-item v-for="({ _id, name, composer }, index) of pieces" :key="'item-' + _id" :style="index > 0 ? 'border-top:1px solid lightgrey' : ''">
                  <v-list-item-content>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ composer }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title>Evaluations</v-card-title>
        <v-tabs v-model="evalTab">
          <v-tab v-for="(evaluation, index) in evaluations" :key="'tab-' + index">{{ evaluation.evaluator.name }}</v-tab>
          <v-spacer/>
          <v-dialog v-if="isAdmin" v-model="evaluatorDialog" width="300">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" outlined style="margin:5px 0 0 10px">
                <v-icon left>fal fa-plus</v-icon>
                Add Eval
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Add Evaluation By</v-card-title>
              <v-card-text>
                <v-text-field v-model="evaluator" label="Evaluator"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="addEvaluation">Start Evaluation</v-btn>
                <v-spacer />
                <v-btn text @click="evaluatorDialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tabs>
        <v-tabs-items v-model="evalTab">
          <v-tab-item v-for="({ ratings, comment, editable }, index) in evaluations" :key="'tab-content-' + index">
            <evaluation
              :ratings="ratings"
              :comment="comment"
              :editable="editable"
              @comment="(comment) => updateComment({ index, comment })"
              @updateRating="({ name, value }) => updateRating({ index, name, value })" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  components: {
    Evaluation: () => import('@/components/student/music/jury/evaluation')
  },
  setup (props, { root }) {
    const newEval = ref(true)
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => root.$store.getters.hasRole(['Technology Services', 'Employee']))
    const id = computed(() => root.$route.params.id)
    const studentName = ref('')
    const instructor = ref('')
    const instrument = ref('')
    const accompanist = ref('')
    const juryDate = ref('')
    const juryTerm = ref('')
    const pieces = ref([])
    const evalTab = ref(0)
    const evaluations = ref([])
    const ratings = ref([])

    const service = root.$feathers.service('student/music/jury-slot')
    const setupService = root.$feathers.service('student/music/jury-setup')

    onMounted(async () => {
      try {
        const data = await service.get(id.value)
        if (data != null) {
          if (isAdmin.value || data.student === user.value.directoryId) {
            const student = await root.$feathers.service('directory/people').get(data.student)
            studentName.value = student.name.first + ' ' + student.name.last
            instructor.value = data.instructor
            instrument.value = data.instrument
            if ('accompanist' in data && data.accompanist != null && data.accompanist !== '') {
              accompanist.value = data.accompanist
            }
            juryDate.value = stringFormatDate(data.start)
            juryTerm.value = (data.term.substring(4, 2) === '05' ? 'Spring ' : 'Fall ') + data.term.substring(0, 4)
            pieces.value = data.pieces
            const { data: setupData } = await setupService.find({ query: { term: data.term } })
            if (setupData.length > 0) {
              const { ratings: r, instruments } = setupData[0]
              const rec = instruments.find(({ name }) => name === instrument.value)
              if (rec && 'ratings' in rec && rec.ratings.length > 0) {
                rec.ratings.forEach((text) => r.push(text))
              }
              ratings.value = r
              loadEvaluations(data.eval)
            } else {
              root.$store.dispatch('main/snackbar', { color: 'error', text: 'There was an error loading the setup for this jury slot. Please contact Tech Services for assistance.' })
            }
          } else {
            root.$store.dispatch('main/snackbar', { color: 'error', text: 'You do not have access to the selected jury slot' })
            root.$router.push('/student/music')
          }
        } else {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'The jury slot you attempted to access does not exist.' })
          if (isAdmin.value) root.$router.push('/student/music/admin')
          else root.$router.push('/student/music')
        }
      } catch (e) {
        console.log(e)
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'The jury slot you attempted to access does not exist.' })
        // if (isAdmin.value) root.$router.push('/student/music/admin')
        // else root.$router.push('/student/music')
      }
    })

    function loadEvaluations (evals) {
      evaluations.value = evals.map((obj) => {
        let editable = false
        if (obj.evaluator.email === user.value.email) editable = true
        return { ...obj, editable }
      })
      if (evals.length > 1) {
        // Add a summary tab
        const summary = {
          comment: [],
          ratings: []
        }
        const hash = {}
        for (let i = 0; i < evals.length; i++) {
          summary.comment.push({
            comment: evals[i].comment,
            name: evals[i].evaluator.name
          })
          for (const { name, value } of evals[i].ratings) {
            if (!(name in hash)) hash[name] = value
            else hash[name] += value
          }
        }
        for (const name in hash) {
          summary.ratings.push({ name, value: hash[name] / evals.length })
        }
        evaluations.value.splice(0, 0, { ...summary, editable: false, evaluator: { name: 'Summary', username: '' } })
      }
    }

    async function saveEvaluation (evaluation, index) {
      console.log(!!evaluation.isNew, index)
      if (isAdmin.value && (evaluation.isNew || evaluation.evaluator.username === user.value.username)) {
        // Build the object to be patched
        const patch = { }
        if (evaluation.isNew) {
          delete evaluation.isNew
          patch.$push = { eval: { ...evaluation } }
        } else {
          if (evaluations.value[0].evaluator.name === 'Summary') index--
          patch['eval.' + index] = evaluation
        }
        // Patch the evaluation then update the stored list of evaluations
        try {
          const data = await service.patch(id.value, patch)
          loadEvaluations(data.eval)
          root.$store.dispatch('main/snackbar', { active: true, color: 'success', text: 'Evaluation saved', timeout: 8000 })
        } catch (e) {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'Error saving evaluation: ' + e, timeout: 8000 })
        }
      }
    }

    async function updateComment ({ index, comment }) {}
    async function updateRating ({ index, name, value }) {}

    const evaluatorDialog = ref(false)
    const evaluator = ref('')
    function addEvaluation () {
      evaluations.value.push({
        isNew: true,
        ratings: ratings.value.map((name) => { return { name, value: 1 } }),
        comment: '',
        evaluator: {
          name: evaluator.value,
          email: user.value.email
        }
      })
      evalTab.value = evaluations.value.length - 1
      evaluatorDialog.value = false
    }

    return {
      newEval,
      user,
      id,
      isAdmin,
      studentName,
      instructor,
      instrument,
      accompanist,
      juryDate,
      juryTerm,
      pieces,
      evalTab,
      evaluations,
      saveEvaluation,
      updateComment,
      updateRating,
      evaluatorDialog,
      evaluator,
      addEvaluation
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1","lg":"8","offset-lg":"2"}},[_c('v-card',{staticClass:"mt-4"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('h2',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Performer: "+_vm._s(_vm.studentName))]),_c('v-list',{attrs:{"elevation":"1"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Jury Date/Time")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.juryDate))])],1),_c('v-list-item-avatar',[_vm._v(_vm._s(_vm.juryTerm))])],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Instructor")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.instructor))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Instrument")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.instrument))])],1)],1),(_vm.accompanist)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Accompanist")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.accompanist))])],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('h2',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Repertoire")]),_c('v-list',{attrs:{"elevation":"1"}},_vm._l((_vm.pieces),function(ref,index){
var _id = ref._id;
var name = ref.name;
var composer = ref.composer;
return _c('v-list-item',{key:'item-' + _id,style:(index > 0 ? 'border-top:1px solid lightgrey' : '')},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(composer))])],1)],1)}),1)],1)],1)],1),_c('v-divider'),_c('v-card-title',[_vm._v("Evaluations")]),_c('v-tabs',{model:{value:(_vm.evalTab),callback:function ($$v) {_vm.evalTab=$$v},expression:"evalTab"}},[_vm._l((_vm.evaluations),function(evaluation,index){return _c('v-tab',{key:'tab-' + index},[_vm._v(_vm._s(evaluation.evaluator.name))])}),_c('v-spacer'),(_vm.isAdmin)?_c('v-dialog',{attrs:{"width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin":"5px 0 0 10px"},attrs:{"outlined":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add Eval ")],1)]}}],null,false,3602754237),model:{value:(_vm.evaluatorDialog),callback:function ($$v) {_vm.evaluatorDialog=$$v},expression:"evaluatorDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Evaluation By")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Evaluator"},model:{value:(_vm.evaluator),callback:function ($$v) {_vm.evaluator=$$v},expression:"evaluator"}})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.addEvaluation}},[_vm._v("Start Evaluation")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.evaluatorDialog = false}}},[_vm._v("Cancel")])],1)],1)],1):_vm._e()],2),_c('v-tabs-items',{model:{value:(_vm.evalTab),callback:function ($$v) {_vm.evalTab=$$v},expression:"evalTab"}},_vm._l((_vm.evaluations),function(ref,index){
var ratings = ref.ratings;
var comment = ref.comment;
var editable = ref.editable;
return _c('v-tab-item',{key:'tab-content-' + index},[_c('evaluation',{attrs:{"ratings":ratings,"comment":comment,"editable":editable},on:{"comment":function (comment) { return _vm.updateComment({ index: index, comment: comment }); },"updateRating":function (ref) {
	var name = ref.name;
	var value = ref.value;

	return _vm.updateRating({ index: index, name: name, value: value });
}}})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }